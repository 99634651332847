<template>
  <div class="input-password">
    <input
      :type="showPassword ? 'text' : 'password'"
      :placeholder="placeholder"
      :value="value"
      :required="required"
      @input="update"
    />

    <i
      :class="showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
      @click="showPassword = !showPassword"
    ></i>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'Password',
  props: {
    value: { type: [String, Number], default: '' },
    placeholder: { type: [String, Number], default: '' },
    clear: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const input = ref(props.value)
    const showPassword = ref(false)
    const update = (e) => emit('update', e.target.value.trim())
    const clear = () => (input.value = '')

    watch(
      () => props.clear,
      (newVal) => {
        if (newVal) clear()
      }
    )

    return {
      update,
      showPassword,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.input-password {
  position: relative;
  margin-bottom: 10px;
  border-radius: 7px;

  input {
    margin-bottom: 0;
  }

  i {
    position: absolute;
    bottom: 12px;
    right: 3%;
    cursor: pointer;
  }
}
</style>
