<template>
  <div class="step__spotlight" :style="{ ...style }">
    <div class="pulse" :style="{ borderRadius: style.borderRadius || '50%' }"></div>
  </div>
</template>

<script>
export default {
  name: 'StepSpotlight',
  props: {
    style: {
      type: Object,
      default: {},
    },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
.step__spotlight {
  position: absolute;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulseAnimation 4s infinite;
}

@keyframes pulseAnimation {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
</style>
