export default {
  state() {
    return {
      status: {},
    }
  },

  mutations: {
    setStatus(state, status) {
      state.status = {}
      state.status = status
    },
  },

  actions: {
    /*
      ROUTE /:organizationId/online-status/:userId
    */
    async updateOnlineStatus({ commit, rootState }, userId) {
      const url = `${rootState.url}/${rootState.user.organization}/online-status/${userId}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.status) commit('setStatus', data.status)

      return false
    },

    /*
      ROUTE /:organizationId/break-status/:userId
    */
    async updateBreakStatus({ commit, rootState }) {
      const url = `${rootState.url}/${rootState.user.organization}/break-status/${rootState.user.id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.status) commit('setStatus', data.status)

      return false
    },

    /*
      ROUTE /:organizationId/reachability-options/:userId
    */
    async updateReachabilityOptions({ commit, rootState }, options) {
      const url = `${rootState.url}/${rootState.user.organization}/reachability-options/${rootState.user.id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ options }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.status) commit('setStatus', data.status)
    },
  },

  getters: {
    getUserStatus(state) {
      return state.status
    },
  },
}
