<template>
  <div v-if="showRotationWarning" class="rotation-warning column center">
    <p>
      {{ t('rotation-warning') }}
    </p>
    <i class="fa-solid fa-arrows-rotate"></i>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'BaseRotationWarning',
  setup() {
    const store = useStore()

    const showRotationWarning = computed(() => {
      return store.state.screen.rotationWarning
    })

    return {
      showRotationWarning,
    }
  },
}
</script>

<style lang="scss" scoped>
.rotation-warning {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--base);
  color: var(--font);
  z-index: 100;

  p {
    margin: 0;
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 30px;
    width: 60%;
  }

  i {
    font-size: 3rem;
  }
}
</style>
