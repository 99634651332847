export default {
  'Get started for free': 'Jetzt kostenlos starten',
  'Sign up': 'Jetzt starten',
  'Welcome Back': 'Willkommen zurück',
  "Don't have an account?": 'Noch kein Account vorhanden?',
  '(min. 4 letters)': '(min. 4 Buchstaben)',
  'Already have an account?': 'Bereits ein Account?',
  'Reset Password': 'Passwort zurücksetzen',
  'Verify Account': 'Account verifizieren',
  'Verification Code': 'Verifizierungscode',
  'Account already verified?': 'Account bereits verifiziert?',
  Password: 'Passwort',
  'Forgot password': 'Passwort vergessen',
  'Enter your email address': 'Wie ist deine E-Mail?',
  Submit: 'Senden',
  Login: 'Login',
  Organization: 'Unternehmen',
  'Min. char': 'Min. Char',
  Uppercase: 'Groß',
  Number: 'Nummer',
  Special: 'Sonderz.',
  Start: 'Start',
  Email: 'Email',
  'demo-01':
    'Bitte beachten: Alle Daten, die in einem Demokonto generiert werden, werden nicht dauerhaft gespeichert.',
  Login: 'Login',
  Language: 'Sprache',
  German: 'Deutsch',
  English: 'Englisch',
  'Legal notice': 'Impressum',
  'Contact us': 'Kontakt',
  Verify: 'Verifizieren',
  'Select language': 'Sprache wählen',
  'Start now': 'Starte jetzt',
  'A verification email has been sent to:': 'Eine Bestätigungs-E-Mail wurde gesendet an:',
  'Request a new verification code': 'Neuen Bestätigungscode anfordern',
  'Start tracking time with ease': 'Starte das Zeiterfassen ganz einfach',
  'Create an account and enjoy all features for free:':
    'Erstelle ein Konto und nutze alle Funktionen kostenlos:',
  'Track time in less than 30 seconds': 'Zeiterfassung in weniger als 30 Sekunden',
  'Save up to 2 hours per week per user': 'Spare bis zu 2 Stunden pro Woche und Nutzer',
  'Add absences, such as sick leave or holidays':
    'Füge Abwesenheiten wie Krankheits- oder Urlaubstage hinzu',
  'Easily export data as an Excel or PDF file':
    'Exportiere Daten ganz einfach als Excel- oder PDF-Datei',
  '...and much more': '...und noch viel mehr',
  OR: 'ODER',
  'Sign in with Google': 'Mit Google anmelden',
}
