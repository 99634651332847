<template>
  <div class="options__list box__shadow column" @click="stopPropag">
    <div
      v-if="options.includes('add')"
      class="option row highlight"
      @click="$emit('optionClick', 'add')"
    >
      <i class="fa-solid fa-plus"></i>
      <span>{{ t('Add') }}</span>
    </div>

    <div
      v-if="options.includes('open')"
      class="option row highlight"
      @click="$emit('optionClick', 'open')"
    >
      <i class="fa-solid fa-up-right-from-square"></i>
      <span>{{ t('Open') }}</span>
    </div>

    <div
      v-if="options.includes('download')"
      class="option row highlight"
      @click="$emit('optionClick', 'download')"
    >
      <i class="fa-solid fa-download"></i>
      <span>{{ t('Download') }}</span>
    </div>

    <div
      v-if="options.includes('edit')"
      class="option row highlight"
      @click="$emit('optionClick', 'edit')"
    >
      <i class="fa-solid fa-pencil"></i>
      <span>{{ t('Edit') }}</span>
    </div>

    <div
      v-if="options.includes('delete')"
      class="option row highlight"
      @click="$emit('optionClick', 'delete')"
    >
      <i class="fa-solid fa-trash"></i>
      <span>{{ t('Delete') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OptionList',
  emits: ['add', 'export', 'edit', 'delete'],
  props: {
    options: { type: Array, default: [] },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
.options__list {
  background-color: var(--base);

  .option {
    width: 130px;
    height: 40px;
    min-height: 40px;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;

    i {
      margin-right: 15px;
    }
  }
}
</style>
