export default [
  {
    path: '/time',
    name: 'Time tracking',
    component: () => import('@modules/time/Time.vue'),
    meta: { authenticated: true, title: 'Sharedex | Timetracking' },
    children: [
      {
        path: 'statistics',
        name: 'Time Statistics',
        component: () => import('@modules/time/views/Statistics.vue'),
      },
      {
        path: 'list',
        name: 'Time List',
        component: () => import('@modules/time/views/List.vue'),
      },
      {
        path: 'team-calendar',
        name: 'Team Calendar',
        component: () => import('@modules/time/views/Calendar.vue'),
      },
    ],
  },
]
