<template>
  <List
    v-if="invoices.length > 0"
    headline="Past invoices"
    :columns="['date', 'plan', 'amount', 'status']"
    :entries="
      invoices.map((invoice) => ({
        id: invoice.id,
        date: buildDateString(invoice.start),
        plan: upperCase(invoice.plan),
        amount: `${(invoice.total / 100).toLocaleString('en-US', {
          minimumFractionDigits: 2,
        })} ${invoice.currency === 'eur' ? '€' : '$'}`,
        status: upperCase(invoice.status),
      }))
    "
    :options="['download', 'open']"
    @optionClick="handleInvoiceOption($event)"
  />
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { buildDateString } from '@lib/helper'

import List from '@components/list/List'

export default {
  name: 'InvoiceList',
  components: { List },
  setup() {
    const store = useStore()

    const invoices = computed(() => {
      return store.getters.getInvoices
    })

    const handleInvoiceOption = (event) => {
      const { key, value } = event
      let invoice = invoices.value.find((invoice) => invoice.id === value.id)
      window.open(key === 'open' ? invoice.url : invoice.pdf, '_blank')
    }

    onBeforeMount(async () => {
      await store.dispatch('getInvoices')
    })

    return {
      invoices,
      handleInvoiceOption,
      buildDateString,
    }
  },
}
</script>

<style lang="scss" scoped>
.invoices {
  width: 100%;
  margin-top: 20px;

  h3 {
    margin: 0 0 7px 0;
  }

  .invoice {
    align-items: center;
    padding: 3px 0;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--dark-2);

    .info {
      width: 20%;
    }

    .plan {
      width: calc(20% - 20px);
      padding-left: 20px;
    }

    .price {
      justify-content: flex-end;
    }

    .status {
      justify-content: center;
    }

    .links {
      justify-content: flex-end;

      a {
        text-decoration: underline !important;
        margin-left: 10px;
      }
    }

    @media (orientation: portrait) {
      flex-direction: column;

      .info {
        width: 100%;
        padding: 0;
        justify-content: flex-start;
      }

      .links {
        justify-content: flex-end;
        margin-top: -37px;

        a {
          margin-left: 20px;
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
