export default [
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('../Authentication.vue'),
    meta: { unAuthenticated: true },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@modules/authentication/views/login'),
        meta: {
          title: 'Sharedex | Welcome Back!',
        },
      },
      {
        path: 'reset-password',
        name: 'Reset Password',
        component: () => import('@modules/authentication/views/reset'),
        meta: {
          title: 'Sharedex | Reset Password',
        },
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@modules/authentication/views/register'),
        meta: {
          title: 'Sharedex | Register Account',
        },
      },
      {
        path: 'verify',
        name: 'Verify',
        component: () => import('@modules/authentication/views/verify'),
        meta: {
          title: 'Sharedex | Verification',
        },
      },
      {
        path: 'demo',
        name: 'Demo',
        component: () => import('@modules/authentication/views/demo'),
        meta: {
          title: 'Sharedex | Start Testing',
        },
      },
    ],
  },
]
