export default {
  Work: 'Work',
  Holiday: 'Holiday',
  Sick: 'Sick',
  Education: 'Education',
  'Holiday (pending)': 'Holiday (pending)',
  'Sick (pending)': 'Sick (pending)',
  'Education (pending)': 'Education (pending)',
  ABSENCE: 'ABSENCE',
  START: 'START',
  END: 'END',
  Break: 'Break',
  Total: 'Total',
  'Download time data': 'Download time data',
  Download: 'Download',
  'Add time entry': 'Add time entry',
  'time-info-01':
    'Attachments will not be saved on our server and will be forwarded via E-Mail to your Team Manager or responsible HR member. Allowed are the file types .jpg, .png and .pdf.',
  'Upload time data': 'Upload time data',
  'time-upload-short-01':
    'Please note, to upload your data correctly, they must have the following structure:',
  'File.xlsx example:': 'File.xlsx example:',
  Date: 'Date',
  Type: 'Type',
  Break: 'Break',
  '2023.08.01': '2023.08.01',
  '8:00 h': '8:00 h',
  '1:00 h': '1:00 h',
  '2023.08.02': '2023.08.02',
  '6:30 h': '6:30 h',
  '2:30 h': '2:30 h',
  'File.csv example:': 'File.csv example:',
  'Date,': 'Date,',
  'Type,': 'Type,',
  'Work,': 'Work,',
  '2023.08.01,': '2023.08.01,',
  '8:00 h,': '8:00 h,',
  '1:00 h,': '1:00 h,',
  '2023.08.02,': '2023.08.02,',
  '6:30 h,': '6:30 h,',
  '2:00 h,': '2:00 h,',
  'time-upload-short-03':
    'We generally suggest you download the preferred file format first, which you can then edit and re-upload. This will ensure your data is in the correct format.',
  'time-upload-short-04':
    'Please note: calculating or including the total time column is not necessary. The system will automatically do this for you.',
  User: 'User',
  From: 'From',
  To: 'To',
  'Total hours': 'Total hours',
  'Expected hours': 'Expected hours',
  'Total break time': 'Total break time',
  'Days of absence': 'Days of absence',
  'Sick leave': 'Sick leave',
  Holidays: 'Holidays',
  Education: 'Education',
  'Work time': 'Work time',
  'Annual leave': 'Annual leave',
  'Weekly hours': 'Weekly hours',
  'Work days': 'Work days',
  Summary: 'Summary',
  Expected: 'Expected',
  View: 'View',
  Dates: 'Dates',
  Options: 'Options',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  May: 'May',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun',
  'Select types': 'Select types',
  'Select timeframe': 'Select timeframe',
  Hours: 'Hours',
  Taken: 'Taken',
  Available: 'Available',
  'Daily Worktime': 'Daily Worktime,',
  Absence: 'Absence',
  '1/2 Day': '1/2 Day',
  'Allowed are the file types .xlsx and .csv.':
    'Allowed are the file types .xlsx and .csv.',
  Start: 'Start',
  End: 'End',
}
