<template>
  <div class="button row" :id="id" :class="[...classes]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SlotBtn',
  props: {
    classes: { type: Array, default: [] },
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
