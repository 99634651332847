<template>
  <SlotBtn v-if="productLink !== '' && plan === 'trial'" class="purchase-btn">
    <a :href="productLink" target="_blank" rel="noopener">
      <i class="fa-solid fa-cart-shopping"></i>
      <span>{{ t('Upgrade subscription') }}</span>
    </a>
  </SlotBtn>

  <SlotBtn v-else-if="plan !== 'trial'" class="purchase-btn">
    <a :href="portalUrl" target="_blank" rel="noopener">
      <i class="fa-solid fa-pen"></i>
      <span>{{ t('Manage subscription') }}</span>
    </a>
  </SlotBtn>
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'

import SlotBtn from '@components/button/SlotBtn.vue'

export default {
  name: 'CheckoutBtns',
  props: {
    plan: { type: String, default: '' },
    productLink: { type: String, default: '' },
  },
  components: { SlotBtn },
  setup() {
    const store = useStore()

    const portalUrl = computed(() => {
      let fallBack = {
        development:
          'https://billing.stripe.com/p/login/test_00g152eAm9zadR6eUU',
        live: 'https://billing.stripe.com/p/login/8wM17BcmNd7o7KM6oo',
      }
      let url = store.getters.getStripePortalUrl
      return url ? url : fallBack[store.state.mode]
    })

    onBeforeMount(async () => {
      await store.dispatch('getStripePortalUrl')
    })

    return {
      portalUrl,
    }
  },
}
</script>

<style lang="scss" scoped>
.purchase-btn {
  margin: 10px auto;

  a {
    color: white;
  }

  i {
    margin-right: 10px;
  }
}
</style>
