<template>
  <div v-if="showOnboarding" class="onboarding__container">
    <div @click="hideOnboarding" class="skip__onboarding row center">
      <i class="fa-solid fa-times"></i>
    </div>

    <StepSpotlight
      v-if="steps[currentStep]?.render"
      @click="steps[currentStep].click"
      :style="steps[currentStep].style"
    />

    <StepSpotlight v-else />

    <StepInfo v-if="steps[currentStep]?.render" :content="steps[currentStep].content" />
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import { createTimeEntry } from '@lib/time.js'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import StepSpotlight from './components/StepSpotlight.vue'
import StepInfo from './components/StepInfo.vue'

export default {
  name: 'Onboarding',
  components: { StepSpotlight, StepInfo },
  setup() {
    const store = useStore()
    const router = useRouter()
    const showOnboarding = computed(() => store.state.user.showOnboarding || false)
    const currentStep = ref(0)
    const device = ref(store.state.screen.device)
    const steps = ref([
      {},
      {
        style: {
          width: 200,
          height: 200,
        },
        selector: '.status__area',
        content: 'step-01',
        click: async () => {
          await createTimeEntry()
          await store.dispatch('updateOnlineStatus', store.state.user.id)
          increaseStepCount()
        },
      },
      {
        style: {
          width: 200,
          height: 200,
        },
        content: 'step-02',
        selector: '.break',
        click: async () => {
          await createTimeEntry()
          await store.dispatch('updateBreakStatus')
          increaseStepCount()
        },
      },
      {
        style: {
          width: 200,
          height: 200,
        },
        content: 'step-03',
        selector: device.value === 'mobile' ? '.fa-bars' : '.collaps__navbar',
        click: async () => {
          store.commit('expandDarboardNav', true)
          increaseStepCount()
        },
      },
      {
        style: {
          width: 250,
          height: 55,
          borderRadius: '7px',
        },
        content: 'step-04',
        selector: '.links .link:nth-child(2)',
        click: async () => {
          store.commit('expandDarboardNav', false)
          router.push('/time/list')
          increaseStepCount()
        },
      },
      {
        style: {
          width: 250,
          height: 55,
          borderRadius: '7px',
        },
        selector: '.options__btns',
        content: 'step-05',
        click: async () => {
          increaseStepCount()
        },
      },
      {
        style: {
          width: window.innerWidth - 20,
          height: 60,
          borderRadius: '7px',
        },
        selector: '.list__entry__header',
        content: 'step-06',
        click: async () => {
          setTimeout(() => {
            document.querySelector('.list__entry__header').click()

            setTimeout(() => {
              const optionBts = document.querySelectorAll('.time__entry__options')

              optionBts.forEach((o) => (o.style.display = 'flex'))

              setTimeout(() => {
                document.querySelector('.time__entry__container').scrollLeft = 1000
              }, 100)
              increaseStepCount()
            }, 100)
          }, 100)
        },
      },
      {
        style: {
          width: 100,
          height: 100,
          borderRadius: '50%',
        },
        content: 'step-07',
        selector: '.options__container',
        click: async () => {
          const options = document.querySelectorAll('.options__container i')
          setTimeout(() => {
            options[options.length - 1].click()
          }, 100)
          increaseStepCount()
        },
      },
      {
        style: {
          width: 200,
          height: 50,
          borderRadius: '7px',
        },
        content: 'step-08',
        selector: '.options__list .option:last-of-type',
        click: async () => {
          document.querySelector('.options__list').lastChild.click()
          increaseStepCount()
        },
      },
    ])

    const waitForElement = (selector, timeout = 5000) => {
      return new Promise((resolve, reject) => {
        const interval = 100
        let elapsed = 0

        const check = () => {
          const el = document.querySelector(selector)
          if (el) {
            resolve(el)
          } else {
            elapsed += interval
            if (elapsed >= timeout) {
              reject(new Error(`Element with selector "${selector}" not found within ${timeout}ms`))
            } else {
              setTimeout(check, interval)
            }
          }
        }

        check()
      })
    }

    const increaseStepCount = async () => {
      currentStep.value += 1
      if (currentStep.value >= steps.value.length) return await hideOnboarding()

      try {
        const current = steps.value[currentStep.value]
        const el = await waitForElement(current.selector)
        const rect = el.getBoundingClientRect()
        const centerX = rect.left + rect.width / 2
        const centerY = rect.top + rect.height / 2

        current.style.left = `${centerX - current.style.width / 2}px`
        current.style.top = `${centerY - current.style.height / 2}px`
        current.style.width = `${current.style.width}px`
        current.style.height = `${current.style.height}px`
        current.render = true
      } catch (error) {
        showOnboarding.value = false
      }
    }

    const hideOnboarding = async () => {
      const updated = await store.dispatch('updateUser', {
        type: 'onboarding',
        id: store.state.user.id,
      })

      if (updated) {
        store.state.user.showOnboarding = false
        currentStep.value = 0
      }
    }

    watch(showOnboarding, (show) => {
      if (show) increaseStepCount()
    })

    return {
      showOnboarding,
      currentStep,
      steps,
      increaseStepCount,
      hideOnboarding,
    }
  },
}
</script>

<style lang="scss" scoped>
.onboarding__container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;

  .skip__onboarding {
    position: fixed;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    background-color: white;
    z-index: 210;
    cursor: pointer;
  }
}

.pending__step {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
