<template>
  <input
    :type="type"
    :step="step"
    :placeholder="placeholder"
    :value="value"
    @input="update"
    :required="required"
    :class="{ 'no-border': !border, 'no-margin': !margin }"
  />
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'Input',
  props: {
    type: { type: String, default: 'text' },
    step: { type: Number, default: 0.1 },
    value: { type: [String, Number], default: '' },
    placeholder: { type: [String, Number], default: '' },
    clear: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    margin: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const input = ref(props.value)
    const update = (e) => emit('update', e.target.value.trim())
    const clear = () => (input.value = '')

    watch(
      () => props.clear,
      (newVal) => {
        if (newVal) clear()
      }
    )

    return {
      update,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.no-border {
  border: none !important;
}

.no-margin {
  margin: 0 !important;
}
</style>
