<template>
  <transition name="translate-x">
    <div
      class="notification-container column scroll-x"
      v-if="notification.messages.length > 0 && notification.show"
    >
      <transition-group name="translate-x">
        <Message
          v-for="message of notification.messages"
          :key="message.created"
          :message="message"
        />
      </transition-group>
    </div>
  </transition>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Message from './Message'

export default {
  name: 'BaseNotification',
  components: { Message },
  setup() {
    const store = useStore()

    const notification = computed(() => {
      return store.state.notification
    })

    return {
      notification,
    }
  },
}
</script>

<style lang="scss" scoped>
.notification-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  align-items: end;
  justify-content: end;

  @media (orientation: portrait) {
    width: 100%;
    align-items: center;
    justify-content: center;
    bottom: 10px;
  }
}
</style>
