<template>
  <component
    v-if="hasPermission(permissions) && hasPlan(plans)"
    :is="href ? 'router-link' : 'div'"
    class="link highlight row"
    :class="{ 'sub-category': subCategory, collapsed: collapsed }"
    :to="href || ''"
    @click="emitCallback"
  >
    <div class="icon row">
      <i :class="icon"></i>
    </div>

    <div class="text nowrap" :title="t(text)">{{ t(text) }}</div>

    <i v-if="expandable" class="fa-solid fa-angle-right" :class="{ expanded: expanded }"></i>
  </component>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'Category',
  emits: ['callback'],
  props: {
    href: { type: String, default: '' },
    icon: { type: String, default: '' },
    text: { type: String, default: '' },
    permissions: { type: Array, default: [] },
    plans: { type: Array, default: [] },
    emit: { type: String, default: '' },
    expandable: { type: Boolean, default: false },
    expanded: { type: Boolean, default: false },
    subCategory: { type: Boolean, default: false },
    collapsed: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const emitCallback = () => {
      if (props.emit) emit('callback', props.emit)
    }

    return {
      emitCallback,
    }
  },
}
</script>

<style lang="scss" scoped>
.link {
  color: var(--font);
  width: calc(90% - 30px);
  padding: 0 15px;
  height: 50px;
  min-height: 50px;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  margin-bottom: 3px;

  .icon {
    width: 30px;
    margin-right: 10px;
    font-size: 1.3rem;
    justify-content: center;
  }

  .fa-angle-right {
    color: var(--dark-2);
    margin-left: auto;
    transition: all 0.2s ease-in-out;

    &.expanded {
      transform: rotate(90deg);
      transition: all 0.2s ease-in-out;
    }
  }

  &.sub-category {
    font-size: 0.95rem;
    height: 40px;
    min-height: 40px;
    width: calc(90% - 45px);
    margin-left: 20px;
    padding: 0 10px;

    .icon {
      font-size: 1rem;
      margin-right: 10px;
    }
  }

  &.collapsed {
    width: 50px;
    padding: 0;
    justify-content: center;

    .icon {
      margin: 0;
      justify-content: center;
    }

    .text,
    .fa-angle-right {
      display: none;
    }
  }
}
</style>
