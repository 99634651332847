<template>
  <div class="label">
    <span>{{ t(text) }}</span>
    <span class="small-label">{{ t(small) }}</span>
  </div>
</template>

<script>
export default {
  name: 'Label',
  props: {
    text: {
      type: String,
      default: '',
    },
    small: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin: 0 0 3px 5px;
  transition: all 0.3s;
  height: 20px;
  padding: 0 2px;
  color: var(--font);

  .small-label {
    font-size: 0.7rem;
    margin-left: 3px;
  }
}
</style>
