import * as XLSX from 'xlsx/xlsx.mjs'
import store from '../store/index.js'
import { buildTimeString } from './helper.js'

export async function createTimeEntry(
  date = new Date().toISOString().split('T')[0]
) {
  try {
    await store.dispatch('addTime', {
      date: date,
      hours: new Date().getHours(),
      minutes: new Date().getMinutes(),
      seconds: new Date().getSeconds(),
    })

    return true
  } catch (error) {
    return false
  }
}

export function createFileName(ending) {
  const email = store.state.time.filterOptions.email
  const start = store.state.time.filterOptions.start
  const end = store.state.time.filterOptions.end

  return `${email}_${start}_${end}.${ending}`
}

export function exportXLSXData(times, fileType = 'xlsx') {
  let worksheets = {}
  worksheets.Sheet1 = [
    ['Date', 'Type', 'Total', 'Break', 'Start', 'End', 'Start', 'End'],
  ]

  for (let day of times) {
    let arr = []
    let date = day.date.split('T')[0]
    arr.push(date.replaceAll('-', '.'))
    arr.push(day.type[0].toUpperCase() + day.type.slice(1))
    arr.push(day.totalWorkTime.string)
    arr.push(day.totalBreakTime.string)

    if (day.type === 'work')
      for (let entry of day.entries) {
        arr.push(buildTimeString(entry.time))
      }

    worksheets.Sheet1.push(arr)
  }

  worksheets.Sheet1.push([
    'Total working hours:',
    store.state.time.monthlyOverview.workHours,
  ])
  worksheets.Sheet1.push([
    'Total break time:',
    store.state.time.monthlyOverview.breakHours,
  ])

  const sheetName = createFileName(fileType)

  const newBook = XLSX.utils.book_new()
  const newSheet = XLSX.utils.aoa_to_sheet(worksheets.Sheet1)
  XLSX.utils.book_append_sheet(newBook, newSheet, 'Sheet1')

  let wbbuf = XLSX.write(newBook, { bookType: fileType, type: 'array' })
  return new Blob([wbbuf], { type: 'application/octet-stream' })
}

export async function exportJSONData(times) {
  for (let time of times) {
    time.owner = store.state.time.filterOptions.email

    delete time._id
    delete time.__v
    delete time.updated

    for (let entry of time.entries) {
      delete entry._id
    }
  }

  const jsonString = JSON.stringify(times, null, 2)
  return new Blob([jsonString], { type: 'application/json' })
}
