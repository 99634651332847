export default {
  'step-01': 'Klicke hier, um die Zeiterfassung zu starten!',
  'step-02': 'Mach eine Pause – klicke hier, um deinen Tag zu pausieren.',
  'step-03': 'Wechsle die Ansicht – klicke hier!',
  'step-04': 'Lieber eine Liste? Wähle hier die Listenansicht aus.',
  'step-05': 'Organisiere deine Zeit: sortieren, filtern, Einträge hinzufügen oder herunterladen.',
  'step-06': 'Tippe hier, um die Details deines Tages zu bearbeiten.',
  'step-07': 'Änderungen vornehmen? Wähle den Bearbeiten-Button aus.',
  'step-08': 'Fertig! Löschen und weiter geht’s.',
}
