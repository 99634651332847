export function getProductLinks(email = '', id = '', env = 'development') {
  let query = `prefilled_email=${email}&client_reference_id=${id}`
  const links = {
    development: {
      startup: {
        monthly: `https://buy.stripe.com/test_fZe8zp7rB59V5MIdQS?${query}`,
        annual: `https://buy.stripe.com/test_dR68zp3bl45R2AwfZ1?${query}`,
      },
      basic: {
        monthly: `https://buy.stripe.com/test_8wM2b14fp45Rdfa8wA?${query}`,
        annual: `https://buy.stripe.com/test_00gg1R9zJ8m74IE5kp?${query}`,
      },
      premium: {
        monthly: `https://buy.stripe.com/test_cN25nd3bl6dZ2Aw14a?${query}`,
        annual: `https://buy.stripe.com/test_3cseXN8vF0TFgrmeV1?${query}`,
      },
      corporate: {
        monthly: `https://buy.stripe.com/test_6oEaHx4fp0TF8YU3ck?${query}`,
        annual: `https://buy.stripe.com/test_eVa2b1aDN45R2AwcMV?${query}`,
      },
    },
    live: {
      startup: {
        monthly: `https://buy.stripe.com/3cs9Eu7Oe5tD1LW3cc?${query}`,
        annual: `https://buy.stripe.com/28o7wm0lMcW5bmwdQR?${query}`,
      },
      basic: {
        monthly: `https://buy.stripe.com/14keYOd8y1dn76gdQT?${query}`,
        annual: `https://buy.stripe.com/eVa2c21pQ4pz2Q0dQU?${query}`,
      },
      premium: {
        monthly: `https://buy.stripe.com/dR66sid8y7BL76g28d?${query}`,
        annual: `https://buy.stripe.com/9AQcQG0lM7BLcqA9AG?${query}`,
      },
      corporate: {
        monthly: `https://buy.stripe.com/3cs3g63xYe09cqA8wD?${query}`,
        annual: `https://buy.stripe.com/bIY3g63xY09j1LWcMU?${query}`,
      },
    },
  }

  return links[env]
}
