<template>
  <div class="value highlight row">
    <div class="checkbox row">
      <i v-if="selected" class="fa-solid fa-check"></i>
    </div>

    <span class="nowrap">
      {{ t(property === 'email' ? value : upperCase(value)) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CheckboxRow',
  props: {
    selected: { type: Boolean, default: false },
    value: { type: String, default: '' },
    property: { type: String, default: '' },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
.value {
  align-items: center;
  padding: 0 10px;
  border-radius: 15px;
  cursor: pointer;
  height: 45px;

  .checkbox {
    border: 1px solid var(--dark-10);
    min-width: 25px;
    min-height: 25px;
    margin-right: 10px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
  }
}
</style>
