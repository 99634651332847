<template>
  <div class="header">
    <h3>{{ t(headline) }}</h3>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    headline: { type: String, defualt: '' },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid var(--dark-2);
  margin-bottom: 15px;

  h3 {
    margin: 10px 0 5px 0;
  }
}
</style>
