export default {
  Work: 'Arbeit',
  Holiday: 'Urlaub',
  Sick: 'Krank',
  Education: 'Fortbildung',
  'Holiday (pending)': 'Urlaub (ausstehend)',
  'Sick (pending)': 'Krank (ausstehend)',
  'Education (pending)': 'Fortbildung (ausstehend)',
  ABSENCE: 'ABWES.',
  START: 'START',
  END: 'ENDE',
  Break: 'Pause',
  Total: 'Gesamt',
  'Download time data': 'Zeitdaten herunterladen',
  Download: 'Download',
  'Add time entry': 'Zeiteintrag hinzufügen',
  'time-info-01':
    'Anhänge werden nicht auf unserem Server gespeichert und per E-Mail an deinen Teamleiter oder zuständigen HR-Mitarbeiter weitergeleitet. Erlaubt sind die Dateitypen .jpg, .png und .pdf.',
  'time-upload-short-01':
    'Bitte beachte, um deine Daten korrekt hochzuladen, müssen sie folgende Struktur haben:',
  'File.xlsx example:': 'Datei.xlsx Beispiel:',
  Date: 'Datum',
  Type: 'Typ',
  'Work,': 'Arbeit,',
  '2023.08.01': '2023.08.01',
  '8:00 h': '8:00 h',
  '1:00 h': '1:00 h',
  '2023.08.02': '2023.08.02',
  '6:30 h': '6:30 h',
  '2:30 h': '2:30 h',
  'File.csv example:': 'Datei.csv Beispiel:',
  'Date,': 'Datum,',
  'Type,': 'Typ,',
  'Total,': 'Gesamt,',
  'Break,': 'Pause,',
  'Start,': 'Start,',
  'End,': 'Ende,',
  '2023.08.01,': '2023.08.01,',
  '8:00 h,': '8:00 h,',
  '1:00 h,': '1:00 h,',
  '2023.08.02,': '2023.08.02,',
  '6:30 h,': '6:30 h,',
  '2:00 h,': '2:00 h,',
  'time-upload-short-03':
    'Wir empfehlen dir generell, zuerst das bevorzugte Dateiformat herunterzuladen, das du dann bearbeiten und erneut hochladen kannst. So stellst du sicher, dass deine Daten im richtigen Format sind.',
  'time-upload-short-04':
    'Bitte beachte: Das Berechnen oder Einschließen der Gesamtsäule ist nicht notwendig. Das System wird dies automatisch für dich übernehmen.',
  User: 'Nutzer',
  From: 'Von',
  To: 'Bis',
  'Total hours': 'Gesamtstunden',
  'Expected hours': 'Erwartete Stunden',
  'Total break time': 'Gesamtpausenzeit',
  'Days of absence': 'Fehltage',
  'Sick leave': 'Krankheitstage',
  Holidays: 'Urlaubstage',
  Education: 'Fortbildung',
  'Work time': 'Arbeitszeit',
  'Annual leave': 'Jahresurlaub',
  'Weekly hours': 'Wöchentliche Stunden',
  'Work days': 'Arbeitstage',
  Summary: 'Zusammenfassung',
  Expected: 'Erwartet',
  View: 'Ansicht',
  Dates: 'Daten',
  Options: 'Optionen',
  January: 'Januar',
  February: 'Februar',
  March: 'März',
  April: 'April',
  May: 'Mai',
  June: 'Juni',
  July: 'Juli',
  August: 'August',
  September: 'September',
  October: 'Oktober',
  November: 'November',
  December: 'Dezember',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mär',
  Apr: 'Apr',
  May: 'Mai',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Okt',
  Nov: 'Nov',
  Dec: 'Dez',
  Mon: 'Mo',
  Tue: 'Di',
  Wed: 'Mi',
  Thu: 'Do',
  Fri: 'Fr',
  Sat: 'Sa',
  Sun: 'So',
  'Select types': 'Typen auswählen',
  'Select timeframe': 'Zeitraum auswählen',
  Hours: 'Stunden',
  Taken: 'Genommen',
  Available: 'Verfügbar',
  'Daily Worktime': 'Arbeitszeiten',
  Absence: 'Abwesenheit',
  '1/2 Day': '1/2 Tag',
  'Allowed are the file types .xlsx and .csv.':
    'Erlaubt sind die Dateitypen .xlsx und .csv.',
  Start: 'Start',
  End: 'Ende',
}
