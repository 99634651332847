export default {
  'step-01': 'Start tracking your time with just one click!',
  'step-02': 'Take a break — click here to pause your day.',
  'step-03': 'Switch it up! Change your view with a click.',
  'step-04': 'Prefer lists? Select the list view here.',
  'step-05': 'Manage your time: sort, filter, add, or download entries.',
  'step-06': "Tap here to tweak your day's details.",
  'step-07': 'Need changes? Select the edit button.',
  'step-08': 'Done and dusted — delete and move forward!',
}
