<template>
  <div class="options__container row">
    <i @click="toggleOptions($event)" ref="optionIcon" class="fa-solid fa-ellipsis-vertical"></i>

    <OptionList
      v-if="showOptions"
      :options="options"
      v-motion
      :initial="{ scale: 0.9 }"
      :enter="{ scale: 1 }"
      :leave="{ scale: 0.9, delay: 100 }"
      :position="position"
      @optionClick="emitOption($event)"
      :style="{ top: `${position.top}px`, right: `${position.right}px` }"
      ref="optionList"
      class="option-list"
    />
  </div>
</template>

<script>
import { ref, reactive, nextTick } from 'vue'

import OptionList from './OptionList'

export default {
  name: 'Options',
  props: {
    options: { type: Array, default: [] },
    scrollContainer: { type: String, default: 'main' },
  },
  emits: ['optionClick'],
  components: { OptionList },
  setup(props, { emit }) {
    const showOptions = ref(false)
    const position = reactive({ top: 0, right: 0 })
    const optionList = ref(null)
    const optionIcon = ref(null)

    const toggleOptions = async (event) => {
      event.stopPropagation()

      showOptions.value = !showOptions.value
      if (!showOptions.value) return

      await nextTick()

      const icon = optionIcon.value.getBoundingClientRect()
      const listWidth = optionList.value.$el.offsetWidth
      const scrollTop = document.querySelector(`.${props.scrollContainer}`).scrollTop || 0

      position.top = icon.top //+ scrollTop - 55
      position.right = window.innerWidth - icon.right - 5

      if (listWidth > icon.left) position.right -= listWidth - icon.left

      const options = document.querySelector('.option-list')
      const clickable = [document.getElementById('app'), ...document.querySelectorAll('form')]

      clickable.forEach((element) => {
        element.addEventListener('click', (e) => {
          if (!options.contains(e.target)) showOptions.value = false
        })
      })
    }

    const emitOption = (option) => {
      emit('optionClick', option)
      showOptions.value = false
    }

    return {
      showOptions,
      position,
      optionList,
      optionIcon,
      toggleOptions,
      emitOption,
    }
  },
}
</script>

<style lang="scss" scoped>
.options__container {
  align-items: center;
  position: relative;

  i {
    cursor: pointer;
    font-size: 1.25rem;
  }

  .option-list {
    position: fixed;
    z-index: 10;
  }
}
</style>
