import { customColor, timePassed } from '@lib/status.js'

export default {
  state() {
    return {
      users: [],
      statusUsers: [],
      editUser: {},
    }
  },

  mutations: {
    setUsers(state, users) {
      state.users = []
      state.users = users.sort((a, b) => {
        return ('' + a.email).localeCompare(b.email)
      })

      state.statusUsers = []
      let userObj = {
        online: [],
        break: [],
        offline: [],
      }

      for (let [i, u] of users.entries()) {
        u.color = customColor(i)
        u.status.lastSeen = timePassed(u.status.updated)

        if (u.status.break) {
          userObj.break.push(u)
        } else if (u.status.online) {
          userObj.online.push(u)
        } else {
          userObj.offline.push(u)
        }
      }

      userObj.online.sort((a, b) => {
        return new Date(a.status.updated) - new Date(b.status.updated)
      })
      userObj.break.sort((a, b) => {
        return new Date(a.status.updated) - new Date(b.status.updated)
      })
      userObj.offline.sort((a, b) => {
        return new Date(b.status.updated) - new Date(a.status.updated)
      })

      state.statusUsers = [
        ...userObj.online,
        ...userObj.break,
        ...userObj.offline,
      ]
    },

    setEditUser(state, user) {
      state.editUser = {}
      state.editUser = user
      if (user.id) state.editUser._id = user.id
    },
  },

  actions: {
    /*
      ROUTE /:organizationId/user/:userId?
    */
    async getUser({ commit, rootState }, userId) {
      const url = `${rootState.url}/${rootState.user.organization}/user/${userId}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.user) return data.user

      return null
    },

    async addUser({ commit, rootState }, user) {
      const url = `${rootState.url}/${rootState.user.organization}/user`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async updateUser({ commit, rootState }, payload) {
      const url = `${rootState.url}/${rootState.user.organization}/user/${payload.type}/${payload.id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ payload }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async deleteUser({ commit, rootState }, userId) {
      const url = `${rootState.url}/${rootState.user.organization}/user/${userId}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        if (userId === rootState.user.id) commit('clearStorage', rootState)
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    /*
      ROUTE /:organizationId/users
    */
    async getUsers({ commit, rootState }) {
      const url = `${rootState.url}/${rootState.user.organization}/users`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.users) {
        commit('setUsers', data.users)

        for (let u of data.users) {
          if (u._id === rootState.user.id) commit('setStatus', u.status)
        }
      }
    },
  },

  getters: {
    getUsers(state) {
      return state.users
    },

    getUsersEmail(state) {
      let users = []
      for (let user of state.users || []) {
        users.push({
          id: user._id,
          email: user.email,
          team: user.team?._id || null,
          role: user.role?._id || null,
          hr: user.hr,
        })
      }

      return users
    },

    getStatusUsers(state) {
      return state.statusUsers
    },

    getEditUser(state) {
      return state.editUser
    },
  },
}
