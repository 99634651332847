<template>
  <Plan
    v-for="plan in plans"
    :key="plan.name"
    :plan="plan"
    :selectedPlan="selectedPlan"
    :billingPeriod="billingPeriod"
    @selectPlan="selectPlan($event)"
    @selectPeriod="selectPeriod($event)"
  />
</template>

<script>
import { useStore } from 'vuex'
import { ref } from 'vue'
import { getProductLinks } from '@lib/stripe.js'

import Plan from './Plan.vue'

export default {
  name: 'Plans',
  emits: ['updateUrl'],
  components: { Plan },
  setup(_, { emit }) {
    const store = useStore()
    const selectedPlan = ref('')
    const billingPeriod = ref('monthly')

    const productLinks = getProductLinks(
      store.state.settings.organization.owner.email,
      store.state.settings.organization._id,
      store.state.mode
    )

    const selectPlan = (plan) => {
      selectedPlan.value = plan
      billingPeriod.value = 'monthly'

      emit('updateUrl', productLinks[selectedPlan.value][billingPeriod.value])
    }

    const selectPeriod = (billingCircle) => {
      billingPeriod.value = billingCircle

      emit('updateUrl', productLinks[selectedPlan.value][billingPeriod.value])
    }

    const plans = [
      { name: 'startup', icon: 'fa-solid fa-user' },
      { name: 'basic', icon: 'fa-solid fa-heart' },
      { name: 'premium', icon: 'fa-solid fa-star' },
      { name: 'corporate', icon: 'fa-solid fa-building' },
    ]

    return {
      billingPeriod,
      selectedPlan,
      selectPlan,
      selectPeriod,
      plans,
    }
  },
}
</script>

<style lang="scss" scoped></style>
