import store from '../../../store'

export default [
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@modules/settings/Settings.vue'),
    meta: { authenticated: true, title: 'Sharedex | Settings' },
    children: [
      {
        path: 'general',
        name: 'General',
        component: () => import('@modules/settings/views/General.vue'),
      },
      {
        path: 'organization',
        name: 'Organization',
        meta: { permissions: ['admin'] },
        component: () => import('@modules/settings/views/Organization.vue'),
        beforeEnter: async () => {
          if (!store.state.settings.organization.name)
            await store.dispatch('getOrganization')
        },
      },
      {
        path: 'teams',
        name: 'Teams',
        meta: { permissions: ['admin', 'hr'] },
        component: () => import('@modules/settings/views/Teams.vue'),
      },
      {
        path: 'roles',
        name: 'Roles',
        meta: { permissions: ['admin', 'hr'] },
        component: () => import('@modules/settings/views/Roles.vue'),
      },
      {
        path: 'subscription',
        name: 'Subscription',
        meta: { permissions: ['admin'] },
        component: () => import('@modules/settings/views/Subscription.vue'),
        beforeEnter: async () => {
          if (!store.state.settings.organization.name)
            await store.dispatch('getOrganization')
        },
      },
      {
        path: 'new-user',
        name: 'New',
        meta: { permissions: ['admin', 'hr'] },
        component: () => import('@modules/settings/views/NewUser.vue'),
      },
      {
        path: 'profile/:id',
        name: 'Edit profile',
        meta: { permissions: ['user'] },
        component: () => import('@modules/settings/views/EditUser.vue'),
        beforeEnter: async (to) => {
          if (!store.state.user.editUser || !store.state.user.editUser._id) {
            const user = await store.dispatch('getUser', to.params.id)
            store.commit('setEditUser', user)
          }
        },
      },
      {
        path: 'edit-user/:id',
        name: 'Edit user',
        meta: { permissions: ['admin', 'hr'] },
        component: () => import('@modules/settings/views/EditUser.vue'),
        beforeEnter: async (to) => {
          if (!store.state.user.editUser || !store.state.user.editUser._id) {
            const user = await store.dispatch('getUser', to.params.id)
            store.commit('setEditUser', user)
          }
        },
      },
    ],
  },
]
