<template>
  <div class="form-steps-container row">
    <div
      @click="changeStep('back')"
      class="navigation row back"
      :class="{ inactive: currentStep === 0, highlight: currentStep !== 0 }"
    >
      <i class="fa-solid fa-caret-left"></i>

      <div class="text">
        <span>{{ t('Back') }}</span>
      </div>
    </div>

    <div class="current row">
      <div
        v-for="i in steps.length"
        :key="i"
        class="step"
        :class="{ active: i === currentStep + 1 }"
      ></div>
    </div>

    <div
      @click="changeStep('next')"
      class="navigation row next"
      :class="{
        inactive: currentStep === steps.length - 1,
        highlight: currentStep !== steps.length - 1,
      }"
    >
      <div class="text">
        <span>{{ t('Next') }}</span>
      </div>

      <i class="fa-solid fa-caret-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Steps',
  emits: ['updateStep'],
  props: {
    steps: { type: Array, default: '' },
    currentStep: { type: Number, default: 0 },
  },
  setup(props, { emit }) {
    const changeStep = (direction) => {
      if (direction === 'back' && props.currentStep === 0) return
      else if (
        direction === 'next' &&
        props.currentStep + 1 === props.steps.length
      )
        return
      else emit('updateStep', direction)
    }

    return {
      changeStep,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-steps-container {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .navigation {
    padding: 5px 15px;
    border-radius: 15px;
    align-items: center;
    justify-content: space-between;

    &.back i {
      margin-right: 10px;
    }

    &.next i {
      margin-left: 10px;
    }

    &.inactive {
      color: var(--gray);
    }
  }

  .current {
    .step {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: var(--highlight-5);
      margin: 0 2px;

      &.active {
        background-color: var(--gray);
      }
    }
  }
}
</style>
