<template>
  <div class="list-header row">
    <div
      class="column"
      v-for="column of columns"
      :key="column"
      :style="{ width: `${100 / columns.length}%` }"
    >
      {{ t(upperCase(column)) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListHeader',
  props: {
    columns: { type: Array, default: [] },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
.list-header {
  width: 100%;
  padding: 0 0 5px 0;
  margin-bottom: 5px;

  .column {
    border-bottom: 1px solid var(--dark-2);
    min-width: 100px;

    &:last-of-type {
      padding-right: 6px;
    }
  }
}
</style>
