<template>
  <div class="toggle-container row">
    <p>{{ t(label) }}</p>

    <div class="outter row" @click="$emit('update', !active)">
      <div :class="{ active: active }" class="toggle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    label: { type: String, default: '' },
    active: { type: Boolean, default: false },
  },
  emits: ['update'],
}
</script>

<style lang="scss" scoped>
.toggle-container {
  width: 100%;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .outter {
    width: 50px;
    height: 25px;
    border-radius: 25px;
    border: 1px solid var(--dark-8);
    align-items: center;
    cursor: pointer;

    .toggle {
      width: 20px;
      height: 20px;
      margin-left: 3px;
      border-radius: 50%;
      background: var(--dark-10);
      transition: all 0.2s ease-in-out;

      &.active {
        margin-left: 27px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
</style>
