<template>
  <div class="settings-options user-list column">
    <div @click="$emit('back')" class="link highlight back row">
      <i class="fa-solid fa-angle-left"></i>
      <span>{{ t('Back') }}</span>
    </div>

    <Search @update="filterUsers($event)" @reset="resetSearch" class="search" />

    <Category
      v-for="user of filteredUsers.length ? filteredUsers : users"
      :key="user._id"
      @click="setEditUser(user), (view = 'edit'), $emit('close')"
      :href="`/settings/edit-user/${user._id}`"
      icon="fa-solid fa-user-gear"
      :text="user.email"
      :admin="true"
      :subCategory="true"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { filterUserList } from '@lib/user'

import Search from '@components/input/Search'
import Category from '@components/navigation/dashboard/components/Category'

export default {
  name: 'UserList',
  emits: ['back', 'close'],
  props: ['view'],
  components: { Search, Category },
  setup() {
    const store = useStore()
    const filteredUsers = ref([])
    const users = computed(() => {
      return store.getters.getUsers
    })

    const setEditUser = (user) => {
      store.commit('setEditUser', user)
    }

    const filterUsers = (filter) => {
      filteredUsers.value = filterUserList(users.value, filter)
    }

    const resetSearch = () => {
      filteredUsers.value = []
    }

    return {
      filteredUsers,
      users,
      setEditUser,
      filterUsers,
      resetSearch,
    }
  },
}
</script>

<style lang="scss" scoped>
.user-list {
  align-items: center;

  .back {
    font-size: 0.95rem;
    height: 40px;
    width: calc(90% - 45px);
    margin-left: 15px;
    padding: 0 15px;
    border-radius: 10px;
    margin-bottom: 5px;
    align-items: center;

    i {
      margin-right: 10px;
    }
  }

  .search {
    font-size: 0.95rem;
    height: 40px;
    width: calc(90% - 15px);
    margin: 0 0 5px 15px;
  }
}
</style>
