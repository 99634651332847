<template>
  <div class="header row" :class="{ expanded: !expanded }">
    <i v-if="mobile" @click="toggleNavbar" class="fa-solid fa-bars"></i>

    <router-link v-else to="/" class="logo row">
      <i class="fa-solid fa-circle-nodes"></i>
      <span> Sharedex </span>
    </router-link>
  </div>

  <div class="dashboard-navigation column" :class="{ expanded: expanded }">
    <div class="links column scroll__y" :class="{ expanded: expanded }">
      <Category
        href="/dashboard"
        icon="fa-solid fa-gauge"
        text="Dashboard"
        :collapsed="mobile ? false : !expanded"
      />

      <Category
        icon="fa-solid fa-clock"
        text="Time"
        :expandable="true"
        :expanded="show.time"
        :collapsed="mobile ? false : !expanded"
        emit="time"
        @callback="handleCallback($event)"
      />
      <transition name="fade">
        <TimeLinks v-if="show.time" />
      </transition>

      <Category
        icon="fa-solid fa-gear"
        text="Settings"
        :expandable="true"
        :expanded="show.settings"
        :collapsed="mobile ? false : !expanded"
        emit="settings"
        @callback="handleCallback($event)"
      />
      <transition name="fade">
        <SettingsLinks v-if="show.settings" />
      </transition>
    </div>

    <IconBtn
      v-if="!mobile"
      class="collaps__navbar"
      :class="{ rotate: !expanded }"
      @click="toggleNavbar"
      icon="fa-solid fa-angle-left"
      :classes="['light']"
    />
  </div>

  <div v-if="mobile && expanded" @click="toggleNavbar" class="dashboard-nav-overlay"></div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

import Category from './components/Category.vue'
import SettingsLinks from '@modules/settings/components/navigation/Navigation.vue'
import TimeLinks from '@modules/time/components/navigation/Navigation.vue'
import IconBtn from '@components/button/IconBtn.vue'

export default {
  name: 'DashboardNavbar',
  emits: ['navbarCollapes'],
  components: { Category, TimeLinks, SettingsLinks, IconBtn },
  setup() {
    const store = useStore()
    const show = reactive({
      time: false,
      settings: false,
    })

    const mobile = computed(() => {
      return store.state.screen.device === 'mobile'
    })

    const expanded = computed(() => {
      return store.state.dashboardNav.expanded
    })

    const toggleNavbar = () => {
      store.commit('expandDarboardNav', !expanded.value)

      if (!expanded.value && !mobile.value) {
        show.time = false
        show.settings = false
      }
    }

    const handleCallback = (category) => {
      show[category] = !show[category]

      if ((show.time || show.settings) && !mobile.value) {
        store.commit('expandDarboardNav', true)
      }
    }

    return {
      show,
      mobile,
      expanded,
      toggleNavbar,
      handleCallback,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.header {
  border-bottom: 1px solid var(--dark-2);
  align-items: center;
  padding: 0 20px;

  @media #{$mobile} {
    padding: 0 10px;
  }

  i {
    font-size: 1.75rem;
    cursor: pointer;
  }

  .logo {
    align-items: center;
    font-size: 1.75rem;
    font-weight: bold;
    letter-spacing: -1px;

    i {
      margin-right: 5px;
      font-size: 2rem;
    }
  }
}

.dashboard-navigation {
  align-items: center;
  width: 74px;
  border-right: 1px solid var(--dark-2);
  height: 100%;
  transition: all 0.2s linear;

  &.expanded {
    width: 299px;
    transition: all 0.2s linear;
  }

  .links {
    width: 100%;
    height: calc(100% - 75px);
    margin: 10px 0 10px 0;
    align-items: center;
  }

  @media #{$mobile} {
    position: absolute;
    top: 55px;
    left: -300px;
    background-color: var(--base);
    z-index: 5;

    &.expanded {
      width: 249px !important;
      left: 0;
    }
  }
}

.dashboard-nav-overlay {
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 250px;
  z-index: 10;
}
</style>
