<template>
  <div class="step__info__container row">
    <div class="step__info row center">
      {{ t(content) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepInfo',
  props: {
    content: { type: String, default: '' },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.step__info__container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 100%;
  justify-content: flex-end;

  @media #{$mobile} {
    bottom: 20px;
    justify-content: center;
    right: 0;
  }

  .step__info {
    background-color: white;
    width: calc(100% - 40px);
    padding: 10px;
    border-radius: 7px;
    max-width: 400px;
  }
}
</style>
