export function timePassed(date) {
  if (!date) return 525800

  let time = new Date(date).getTime()
  let now = new Date().getTime()
  let minutes = Math.round((now - time) / 80000)

  if (minutes <= 59) return `${minutes} min`
  else if (minutes < 1440) return `${Math.round(minutes / 60)} h`
  else return `${Math.round(minutes / 60 / 24)} d`
}

export function customColor(index) {
  let list = [
    '#f4c2c2',
    '#008080',
    '#367588',
    '#006d5b',
    '#e2725b',
    '#d8bfd8',
    '#de6fa1',
    '#fc89ac',
    '#0abab5',
    '#e08d3c',
    '#dbd7d2',
    '#eee800',
    '#ff6347',
    '#746cc0',
    '#ffc87c',
    '#fd0e35',
    '#808080',
    '#00755e',
    '#0073cf',
    '#417dc1',
    '#deaa88',
    '#b57281',
    '#30d5c8',
    '#00ffef',
    '#a0d6b4',
    '#66424d',
    '#8a496b',
    '#66023c',
    '#0033aa',
    '#d9004c',
    '#536895',
    '#ffb300',
    '#3cd070',
    '#014421',
    '#7b1113',
    '#990000',
    '#ffcc00',
    '#8878c3',
    '#ff6fff',
    '#120a8f',
    '#4166f5',
    '#635147',
    '#5b92e5',
    '#b78727',
    '#ffff66',
    '#ae2029',
    '#e1ad21',
    '#d3003f',
    '#f3e5ab',
    '#c5b358',
    '#c80815',
    '#43b3ae',
    '#e34234',
    '#a020f0',
    '#ee82ee',
    '#324ab2',
    '#f75394',
    '#40826d',
    '#922724',
    '#9f1d35',
    '#da1d81',
    '#ffa089',
    '#9f00ff',
    '#004242',
    '#00ffff',
    '#645452',
    '#f5deb3',
    '#ffffff',
    '#f5f5f5',
    '#ff43a4',
    '#fc6c85',
    '#a2add0',
    '#722f37',
    '#c9a0dc',
    '#738678',
    '#0f4d92',
    '#ffff00',
    '#ffae42',
    '#9acd32',
    '#0014a8',
    '#2c1608',
  ]
  return list[index]
}
