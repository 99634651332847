<template>
  <div class="search-input row">
    <Input
      type="text"
      :value="searchValue"
      v-model="searchValue"
      @update="updateInput($event)"
      :border="false"
      :margin="false"
    />

    <div class="icon-grp row">
      <i
        v-if="searchValue !== ''"
        class="fa-solid fa-xmark"
        @click="resetSearch"
      ></i>

      <div v-if="searchValue !== ''" class="line"></div>
      <i class="fa-solid fa-magnifying-glass"></i>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import Input from '@components/input/Input'

export default {
  name: 'Search',
  components: { Input },
  emits: ['update', 'reset'],
  setup(_, { emit }) {
    const searchValue = ref('')

    const updateInput = (phrase) => {
      if (phrase === '') return emit('resetSearch')
      searchValue.value = phrase
      emit('update', searchValue.value)
    }

    const resetSearch = () => {
      searchValue.value = ''
      emit('reset')
    }

    return {
      searchValue,
      updateInput,
      resetSearch,
    }
  },
}
</script>

<style lang="scss" scoped>
.search-input {
  width: 100%;
  height: 43px;
  max-height: 43px;
  border: 1px solid var(--dark-2);
  border-radius: 15px;
  align-items: center;
  z-index: 10;

  .icon-grp {
    .line {
      border: 1px solid var(--dark-2);
      margin-right: 10px;
    }

    i {
      margin-right: 10px;
      font-size: 1.25rem;
      cursor: pointer;
    }
  }
}
</style>
