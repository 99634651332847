import { getDateOfMonth } from '@lib/helper.js'

export default {
  state() {
    return {
      dataFetched: false,
      calendar: [],
      teamCalendar: [],
      monthlyOverview: {},
      filterOptions: {
        email: '',
        id: null,
        start: getDateOfMonth('first'),
        end: getDateOfMonth('last'),
        sorting: 'asc',
        types: ['work', 'sick', 'holiday', 'education'],
      },
      timeStatistics: {},
    }
  },

  mutations: {
    setCalendar(state, calendar) {
      state.calendar = []
      state.calendar = [...calendar]
    },

    setTimeFilter(state, payload) {
      state.filterOptions[payload.type] = payload.value
    },

    resetTimeFilter(state, payload) {
      state.filterOptions = {
        email: payload.email,
        id: payload.id,
        start: getDateOfMonth('first'),
        end: getDateOfMonth('last'),
        sorting: 'asc',
        types: ['work', 'sick', 'holiday', 'education'],
      }
    },

    setMonthlyOverview(state, payload) {
      state.monthlyOverview = {}
      state.monthlyOverview = payload
    },

    setTimeStatistics(state, payload) {
      state.timeStatistics = {}
      state.timeStatistics = payload
    },

    setTeamCalendar(state, calendar) {
      state.teamCalendar = []
      state.teamCalendar = calendar
    },
  },

  actions: {
    /* 
      ROUTE /:organizationId/time/:userId
    */
    async getTimes({ state, commit, rootState }) {
      const id = state.filterOptions.id
      const start = state.filterOptions.start
      const end = state.filterOptions.end
      const sorting = state.filterOptions.sorting
      const types = [...state.filterOptions.types]

      const url = `${rootState.url}/${rootState.user.organization}/time/${id}?start=${start}&end=${end}&sorting=${sorting}&types=${types}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.calendar) commit('setCalendar', data.calendar)
    },

    async addDayEntry({ state, commit, rootState }, payload) {
      const url = `${rootState.url}/${rootState.user.organization}/time/${state.filterOptions.id}`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payload: payload,
        }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async updateDayEntry({ state, commit, rootState }, payload) {
      const url = `${rootState.url}/${rootState.user.organization}/time/${state.filterOptions.id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ payload }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })

        return true
      }

      return false
    },

    async deleteDayEntry({ state, commit, rootState }, date) {
      const url = `${rootState.url}/${rootState.user.organization}/time/${state.filterOptions.id}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ date }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })

        return true
      }

      return false
    },

    /* 
      ROUTE /:organizationId/time/team-calendar/:userId
    */
    async getTeamCalendar({ state, commit, rootState }, payload) {
      const id = state.filterOptions.id

      const url = `${rootState.url}/${rootState.user.organization}/time/team-calendar/${id}?start=${payload.start}&end=${payload.end}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.calendar) commit('setTeamCalendar', data.calendar)
    },

    /* 
      ROUTE /:organizationId/time/team-calendar/:userId
    */
    async getTimesStatistics({ state, commit, rootState }) {
      const id = rootState.user.id
      const start = state.filterOptions.start
      const end = state.filterOptions.end
      const sorting = state.filterOptions.sorting
      const types = [...state.filterOptions.types]

      const url = `${rootState.url}/${rootState.user.organization}/time/statistics/${id}?start=${start}&end=${end}&sorting=${sorting}&types=${types}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.monthlyOverview)
        commit('setMonthlyOverview', data.monthlyOverview)
      if (data.timeStatistics) commit('setTimeStatistics', data.timeStatistics)
    },

    /*
      ROUTE /:organizationId/time/entry-status/:entryId/:userId
    */
    async updateTimeEntryStatus({ commit, rootState }, payload) {
      const url = `${rootState.url}/${rootState.user.organization}/time/entry-status/${payload.entryId}/${payload.ownerId}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: payload.status,
        }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) return true

      return false
    },

    /* 
      ROUTE /:organizationId/time/day/:userId
    */
    async addTime({ state, commit, rootState }, payload) {
      const url = `${rootState.url}/${rootState.user.organization}/time/day/${state.filterOptions.id}`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payload: payload,
        }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success)
        commit('showNotification', { type: 'success', message: data.success })

      return false
    },

    async deleteTime({ state, commit, rootState }, date) {
      const url = `${rootState.url}/${rootState.user.organization}/time/day/${state.filterOptions.id}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ date: date }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })

        return true
      }

      return false
    },

    /* 
      ROUTE /:organizationId/upload-times/:userId
    */
    async uploadTimes({ state, commit, rootState }, payload) {
      const url = `${rootState.url}/${rootState.user.organization}/upload-times/${state.filterOptions.id}`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          timeArr: payload.timeArr,
        }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },
  },

  getters: {
    getCalendar(state) {
      return state.calendar
    },

    getTeamCalendar(state) {
      return state.teamCalendar
    },

    getTimeFilter(state) {
      return state.filterOptions
    },

    getMonthlyOverview(state) {
      return state.monthlyOverview
    },

    getTimeStatistics(state) {
      return state.timeStatistics
    },
  },
}
