import store from './store'
import router from './router'
import translation from './translation'
import addRoutes from '@lib/router.js'
import addStore from '@lib/store.js'
import addTranslation from '@lib/translate.js'

addRoutes(router)
addStore('time', store)
addTranslation(translation)

export default {
  store,
  router,
}
