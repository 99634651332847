<template>
  <button :id="id" :class="[...classes]">
    <Spinner v-if="loadingBtnId !== '' && loadingBtnId === id" />

    <span v-else>
      {{ t(text) }}
    </span>
  </button>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Spinner from '@components/loading/Spinner'

export default {
  name: 'Submit',
  components: { Spinner },
  props: {
    text: { type: String, default: 'Submit' },
    id: { type: String, default: '' },
    classes: { type: Array, default: [] },
  },
  setup() {
    const store = useStore()

    const loadingBtnId = computed(() => {
      return store.state.loading.id
    })

    return {
      loadingBtnId,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
