export default {
  'Get started for free': 'Get started for free',
  'Sign up': 'Sign up',
  'Welcome Back': 'Welcome Back',
  "Don't have an account?": "Don't have an account?",
  '(min. 4 letters)': '(min. 4 letters)',
  'Already have an account?': 'Already have an account?',
  'Reset Password': 'Reset Password',
  'Verify Account': 'Verify Account',
  'Verification Code': 'Verification Code',
  'Account already verified?': 'Account already verified?',
  Password: 'Password',
  'Forgot password': 'Forgot password',
  'Enter your email address': 'Enter your email address',
  Submit: 'Submit',
  Login: 'Login',
  Organization: 'Organization',
  'Min. char': 'Min. char',
  Uppercase: 'Uppercase',
  Number: 'Number',
  Special: 'Special',
  Start: 'Start',
  Email: 'Email',
  'demo-01': 'Please note: All data generated in a demo account will not be stored permanently.',
  'Sign up': 'Sign up',
  Login: 'Login',
  Language: 'Language',
  German: 'German',
  English: 'English',
  'Legal notice': 'Legal notice',
  'Data privacy': 'Data privacy',
  'Contact us': 'Contact us',
  Verify: 'Verify',
  'Select language': 'Select language',
  'Start now': 'Start now',
  'A verification email has been sent to:': 'A verification email has been sent to:',
  'Request a new verification code': 'Request a new verification code',

  'Start tracking time with ease': 'Start tracking time with ease',
  'Create an account and enjoy all features for free:':
    'Create an account and enjoy all features for free:',
  'Track time in less than 30 seconds': 'Track time in less than 30 seconds',
  'Save up to 2 hours per week per user': 'Save up to 2 hours per week per user',
  'Add absences, such as sick leave or holidays': 'Add absences, such as sick leave or holidays',
  'Easily export data as an Excel or PDF file': 'Easily export data as an Excel or PDF file',
  '...and much more': '...and much more',

  OR: 'OR',
  'Sign in with Google': 'Sign in with Google',
}
