<template>
  <div class="static row">
    <span>{{ t(value) }}</span>
  </div>
</template>

<script>
export default {
  name: 'Static',
  props: {
    value: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.static {
  align-items: center;
  cursor: not-allowed;
}
</style>
